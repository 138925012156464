import { Stack } from '@chakra-ui/react';
import { HistogramData, ISeriesApi } from 'lightweight-charts';
import { LineSeries } from 'lightweight-charts-react-wrapper';
import React from 'react';
import { calculateVolumeSMA } from '../../../../../../utils/pro/sma';

type HistogramMALineSeriesProps = {
    MA10Ref: React.RefObject<ISeriesApi<'Line'>>;
    data: HistogramData[];
    visible: boolean;
};
const HistogramMALineSeries: React.FC<HistogramMALineSeriesProps> = (props) => {
    const { MA10Ref, data, visible = true } = props;

    const sma10 = calculateVolumeSMA(data, 10);

    return (
        <Stack>
            {sma10.length > 0 && (
                <LineSeries
                    data={sma10}
                    color="#E68617"
                    lineWidth={1}
                    ref={MA10Ref}
                    crosshairMarkerVisible={false}
                    lastValueVisible={false}
                    priceLineVisible={false}
                    visible={visible}
                />
            )}
        </Stack>
    );
};

export default React.memo(HistogramMALineSeries);
