import { Center, VStack, Image, useColorMode } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useConnectModal } from '../../../../../providers/ConnectProvider';
import { i_text_copy } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';

const NoConnected: React.FC = () => {
    const { onOpenModal: onConnect } = useConnectModal();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Center h="500px" bg={colorTheme('#FFFFFF', '#0B071E')}>
            <VStack>
                <Image w="136px" h="161px" src="/assets/pro/orderForm/noConnect.svg"></Image>
                <CustomButton
                    variant="purple"
                    onClick={onConnect}
                    text="Connect Wallet"
                    className={i_text_copy}
                    fontWeight="700"
                    w="152px"
                    h="36px"
                    mt="30px !important"
                ></CustomButton>
            </VStack>
        </Center>
    );
};

export default NoConnected;
