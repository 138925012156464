import { Text, Flex, Tooltip, IconButton, BoxProps, Select, useColorMode } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon, ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import { i_text_copy } from '../../../style';
import React, { useEffect } from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';

type PaginationProps = {
    totalPage: number;
    currentPage: any;
    pageSize: any;
    setCurrentPage: (v: any) => void;
    setPageSize: (v: any) => void;
} & BoxProps;

export const Pagination: React.FC<PaginationProps> = (props) => {
    const { totalPage, currentPage, pageSize, setCurrentPage, setPageSize, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const downSvg = React.createElement('img', {
        src: colorTheme(
            process.env.PUBLIC_URL + '/assets/orderHistory/down.svg',
            process.env.PUBLIC_URL + '/assets/orderHistory/darkDown.svg'
        ),
    });

    useEffect(() => {
        if (currentPage != 1 && currentPage > totalPage) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPage]);

    return (
        <>
            <Flex
                px={{ base: '0px', sm: '20px' }}
                direction={{ base: 'column', sm: 'row' }}
                justifyContent="space-between"
                m={4}
                alignItems={{ base: 'start', sm: 'center' }}
                {...rest}
            >
                <Flex alignItems="center">
                    <Text className={i_text_copy} color={colorTheme('tertiary.600', 'tertiary.400')} mr="13px">
                        {t('Rows per page')}
                    </Text>
                    <Select
                        w="85px"
                        value={pageSize}
                        className={i_text_copy}
                        fontSize="12px"
                        size="sm"
                        borderRadius="3px"
                        onChange={(v) => {
                            setPageSize(v.target.value);
                        }}
                        bgColor={colorTheme('#F5F5F5', '#3A2F53')}
                        borderWidth="0px"
                        icon={downSvg}
                    >
                        {[5, 10, 15, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex alignItems="center" mt={{ base: '20px', sm: '0px' }}>
                    <Tooltip label="First Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(1);
                            }}
                            isDisabled={currentPage === 1}
                            size="xs"
                            borderRadius="3px"
                            icon={<ArrowLeftIcon h={2} w={2} />}
                            mr={4}
                        />
                    </Tooltip>
                    <Tooltip label="Previous Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                            isDisabled={currentPage === 1}
                            size="xs"
                            borderRadius="3px"
                            icon={<ChevronLeftIcon h={4} w={4} />}
                        />
                    </Tooltip>

                    <Text className={i_text_copy} flexShrink={0} mx={8}>
                        Page <Text as="span">{currentPage}</Text> of <Text as="span">{totalPage}</Text>
                    </Text>

                    <Tooltip label={t('Next Page')}>
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                            }}
                            isDisabled={currentPage >= totalPage}
                            size="xs"
                            borderRadius="3px"
                            icon={<ChevronRightIcon h={4} w={4} />}
                        />
                    </Tooltip>

                    <Tooltip label={t('Last Page')}>
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(totalPage);
                            }}
                            isDisabled={currentPage >= totalPage}
                            size="xs"
                            borderRadius="3px"
                            icon={<ArrowRightIcon h={2} w={2} />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </>
    );
};
