import { Tag, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { getColorThemeSelector } from '../../../../../utils/funcs';

export const ReadyToClaimTag: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Tag
            px="32px"
            position="absolute"
            top="0"
            left="32px"
            transform="translateY(-50%)"
            color={colorTheme('primary.500', 'primary.50')}
            bg={colorTheme('primary.100', '#2252CC')}
            borderColor="#5F9EFF"
            border={colorTheme('1px', '0')}
            fontSize="12px"
            lineHeight="15px"
            fontWeight="600"
            fontFamily="Montserrat"
        >
            Ready To Claim
        </Tag>
    );
};
