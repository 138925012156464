import { style, media } from 'typestyle';

export const tabsBtnStyle = style({
    // borderRadius: '6px',//TODO
    $nest: {
        '&:last-of-type': {
            boxShadow: '0px 0px 27px 5px rgba(218, 206, 230, 0.25)',
        },
    },
});
