import { isAddress } from 'ethers/lib/utils';
import { isNumeric } from '../../../../utils/valid';
import { getSortedTokenAddr } from '../../common/positionPoolHelper';
import { AddLimitOrderForm } from './tradeLimitOrder';

export const validateLimitOrderForm = (form: AddLimitOrderForm, tokenBalance: number, poolPriceDecimal: number): string[] | undefined => {
    if (!isAddress(form.tokenX.address)) {
        return ['tokenX', 'invalidate token X address !'];
    }
    if (!isAddress(form.tokenY.address)) {
        return ['tokenX', 'invalidate token Y address !'];
    }
    if (!isNumeric(form.amountDecimal) || form.amountDecimal < 0) {
        return ['amount', 'please enter correct amount !'];
    }

    if (form.amountDecimal > tokenBalance) {
        return ['amount', 'Insufficient balance !'];
    }

    const [tokenLowerAddr] = getSortedTokenAddr(form.tokenX.address, form.tokenY.address);
    const isFlipped = tokenLowerAddr !== form.tokenX.address;

    if (isFlipped && form.sellPriceDecimal < poolPriceDecimal) {
        return ['sellPrice', 'sellPrice must greater than current price !'];
    }
    if (!isFlipped && form.sellPriceDecimal < poolPriceDecimal) {
        return ['sellPrice', 'sellPrice must greater than current price !'];
    }

    if (!isNumeric(poolPriceDecimal)) {
        return ['poolPrice', 'invalidate pool !'];
    }
};