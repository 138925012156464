
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { useGasToken, useWrappedGasTokenIfExists } from '../../hooks/useGasToken'

export const useGasOrWrappedToken = (token: TokenInfoFormatted, strictGasToken: boolean): TokenInfoFormatted => {
    const gasToken = useGasToken()
    const wrappedGasToken = useWrappedGasTokenIfExists()
    if (!token.address) {
        return token
    }
    if (token.address.toLowerCase() === gasToken.address.toLowerCase()) {
        return strictGasToken ? gasToken : wrappedGasToken
    }
    return token
}