import { useMemo } from "react";
import { getGasToken, getWrappedGasTokenIfExists } from "../../../config/tokens";
import { TokenInfoFormatted } from "../../../hooks/useTokenListFormatted";
import { useWeb3WithDefault } from "../../../hooks/useWeb3WithDefault";


export const useGasToken = (): TokenInfoFormatted => {
    const { chainId } = useWeb3WithDefault();
    return useMemo(() => getGasToken(chainId), [chainId]);
};

export const useWrappedGasTokenIfExists = (): TokenInfoFormatted => {
    const { chainId } = useWeb3WithDefault();
    return useMemo(() => getWrappedGasTokenIfExists(chainId), [chainId]);
}