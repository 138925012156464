import { Stack } from '@chakra-ui/react';
import { CandlestickData, ISeriesApi } from 'lightweight-charts';
import { LineSeries } from 'lightweight-charts-react-wrapper';
import React from 'react';
import { calculateSMA } from '../../../../../utils/pro/sma';

type MALineSeriesProps = {
    MA10Ref: React.RefObject<ISeriesApi<'Line'>>;
    MA30Ref: React.RefObject<ISeriesApi<'Line'>>;
    MA60Ref: React.RefObject<ISeriesApi<'Line'>>;
    data: CandlestickData[];
    visible: boolean;
};
const MALineSeries: React.FC<MALineSeriesProps> = (props) => {
    const { MA10Ref, MA30Ref, MA60Ref, data, visible = true } = props;

    const sma10 = calculateSMA(data, 10);
    const sma30 = calculateSMA(data, 30);
    const sma60 = calculateSMA(data, 60);

    return (
        <Stack>
            {sma10.length > 0 && (
                <LineSeries
                    data={sma10}
                    color="#D17A13"
                    lineWidth={1}
                    ref={MA10Ref}
                    crosshairMarkerVisible={false}
                    lastValueVisible={false}
                    priceLineVisible={false}
                    visible={visible}
                />
            )}
            {sma30.length > 0 && (
                <LineSeries
                    data={sma30}
                    color="#3EAEFF"
                    lineWidth={1}
                    ref={MA30Ref}
                    crosshairMarkerVisible={false}
                    lastValueVisible={false}
                    priceLineVisible={false}
                    visible={visible}
                />
            )}
            {sma60.length > 0 && (
                <LineSeries
                    data={sma60}
                    color="#EC35D8"
                    lineWidth={1}
                    ref={MA60Ref}
                    crosshairMarkerVisible={false}
                    lastValueVisible={false}
                    priceLineVisible={false}
                    visible={visible}
                />
            )}
        </Stack>
    );
};

export default React.memo(MALineSeries);
