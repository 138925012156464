import {
    Stack,
    Image,
    HStack,
    Center,
    Button,
    MenuButton,
    Text,
    Menu,
    MenuItem,
    MenuList,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorMode,
} from '@chakra-ui/react';
import { useInterval } from 'ahooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PRO_CONFIG } from '../../../../../config/bizConfig';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';

import { FetchOrderBookParams } from '../../../../../state/models/pro/proOrderBook/types';
import { RootDispatch, RootState } from '../../../../../state/store';
import { noto_t1_bold, noto_t3 } from '../../../../../style';
import { getPoolContractByAddress } from '../../../../../utils/contractFactory';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { izumiFeeToTickSpacingMapping } from '../../../../../utils/tickMath';
import { formatNumber } from '../../../../../utils/tokenMath';
import { trades } from '../OrderBook';
import CurrentPriceBlock from './CurrentPriceBlock';
import OrderBuyBlock from './OrderBuyBlock';
import OrderSellBlock from './OrderSellBlock';

type OrderBookBlockProps = {
    trades: trades[];
};

const OrderBookBlock: React.FC<OrderBookBlockProps> = (props) => {
    const { trades } = props;
    const [currentType, setCurrentType] = useState('all');
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [group, setGroup] = useState({ delta: 10, rate: '0.1%' });

    const { web3, chainId } = useWeb3WithDefault();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { proOrderFormState } = useSelector((state: RootState) => state);
    const { token } = useSelector((state: RootState) => state);

    const { pro, proControlState } = useSelector((state: RootState) => state);
    const gasToken = useGasToken();
    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);

    const realPrice =
        trades.length > 0 &&
        trades[0].price &&
        (trades[0].actionType === 'buy'
            ? trades[0].price / (1 + toFeeNumber(pro.currentFee) / 100)
            : trades[0].price / (1 - toFeeNumber(pro.currentFee) / 100));

    const refreshOrdersData = () => {
        if (proOrderFormState.chainId !== chainId || !proOrderFormState.poolState.poolAddress) {
            return;
        }
        const pointDelta = izumiFeeToTickSpacingMapping[proOrderFormState.fee];
        const poolContract = getPoolContractByAddress(chainId, web3, proOrderFormState.poolState.poolAddress);
        dispatch.proOrderBook
            .fetch({
                poolContract,
                currentPoint: Number(proOrderFormState.poolState.currentPoint),
                currentLiquidity: proOrderFormState.poolState.liquidity,
                currentLiquidityX: proOrderFormState.poolState.liquidityX,
                orderBookPointDelta: group.delta,
                baseToken: proOrderFormState.baseToken,
                quoteToken: proOrderFormState.quoteToken,
                pointDelta,
                upNum: 30,
                lowNum: 30,
            } as FetchOrderBookParams)
            .catch((e) => {
                console.log('error while fetch order book: ', e);
            });
    };

    useInterval(() => {
        refreshOrdersData();
    }, PRO_CONFIG.ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL);

    const typeSelect = (type: string) => {
        const isSelected = currentType === type;
        return (
            <Center
                w="28px"
                h="30px"
                opacity={isSelected ? 1 : '0.3'}
                borderRadius="2px"
                cursor="pointer"
                onMouseDown={() => {
                    setCurrentType(type);
                }}
                _hover={{ opacity: 1 }}
            >
                <Center w="28px" h="24px" bg={colorTheme(isSelected ? '#DEE1FF' : '#F2F0F5', isSelected ? '#1B1631' : '#241C3C')}>
                    <Image w="20px" h="16px" src={'/assets/pro/orderBook/' + type + '.svg'}></Image>
                </Center>
            </Center>
        );
    };

    const titleBlock = (priceType: string, quantityType: string, totalType: string) => {
        return (
            <Stack w="100%" my="7px">
                <HStack w="100%" className={noto_t3} color={colorTheme('#767676', '#83789B')} spacing="0px">
                    <Text w="33%" whiteSpace="nowrap">
                        Price({priceType})
                    </Text>
                    <Text w="33%" textAlign="end" whiteSpace="nowrap">
                        Quantity({quantityType})
                    </Text>
                    <Text w="33%" textAlign="end" whiteSpace="nowrap">
                        Total({totalType})
                    </Text>
                </HStack>
            </Stack>
        );
    };

    return (
        <Stack w="100%">
            <HStack>
                <Tabs w="100%" variant="unstyled">
                    <TabList>
                        <Tab h="30px" p="0px">
                            {typeSelect('all')}
                        </Tab>
                        <Tab h="30px" mx="12px" p="0px">
                            {typeSelect('sell')}
                        </Tab>
                        <Tab h="30px" p="0px">
                            {typeSelect('buy')}
                        </Tab>
                        <Menu>
                            <MenuButton
                                h="30px"
                                as={Button}
                                bg="transparent"
                                borderRadius="12px"
                                px="0px"
                                ml="auto !important"
                                _hover={{ background: 'unset' }}
                                _active={{ background: 'unset' }}
                            >
                                <HStack>
                                    <Text className={noto_t3} color={colorTheme('#767676', '#83789B')}>
                                        {group.rate}
                                    </Text>
                                    <Center boxSize="16px" bg={colorTheme('#E5E3F2', '#3A2F53')} borderRadius="2px">
                                        <Image src={process.env.PUBLIC_URL + '/assets/pro/orderBook/arrowDown.svg'}></Image>
                                    </Center>
                                </HStack>
                            </MenuButton>
                            <MenuList minW="120px" zIndex={3} bg={colorTheme('#ffffff', '#140E28')}>
                                <MenuItem
                                    variant="light"
                                    size="md"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                    }}
                                    onClick={() => {
                                        setGroup({ delta: 10, rate: '0.1%' });
                                    }}
                                >
                                    0.1%
                                </MenuItem>
                                <MenuItem
                                    variant="light"
                                    size="md"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                    }}
                                    onClick={() => {
                                        setGroup({ delta: 50, rate: '0.5%' });
                                    }}
                                >
                                    0.5%
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </TabList>

                    <TabPanels>
                        <TabPanel p="0px">
                            {titleBlock(
                                pro.tokenY.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol
                            )}
                            <OrderSellBlock currentType={currentType} trades={trades} realPrice={realPrice}></OrderSellBlock>

                            <CurrentPriceBlock trades={trades} realPrice={realPrice} my="5px"></CurrentPriceBlock>
                            <OrderBuyBlock currentType={currentType} trades={trades} realPrice={realPrice}></OrderBuyBlock>
                        </TabPanel>
                        <TabPanel p="0px">
                            {titleBlock(
                                pro.tokenY.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol
                            )}
                            <OrderSellBlock currentType={currentType} trades={trades} realPrice={realPrice}></OrderSellBlock>
                        </TabPanel>
                        <TabPanel p="0px">
                            {titleBlock(
                                pro.tokenY.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol,
                                proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol
                            )}
                            <OrderBuyBlock currentType={currentType} trades={trades} realPrice={realPrice}></OrderBuyBlock>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </HStack>
        </Stack>
    );
};

export default OrderBookBlock;
