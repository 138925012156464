import { CandlestickData, HistogramData } from 'lightweight-charts';

export function calculateSMA(data: CandlestickData[], count: number) {
    if (count > data.length) {
        return [];
    }
    const avg = (data: CandlestickData[]) => {
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
            sum += data[i].close;
        }
        return sum / data.length;
    };
    const result = [];
    for (let i = count - 1, len = data.length; i < len; i++) {
        const val = avg(data.slice(i - count + 1, i));
        result.push({ time: data[i].time, value: val });
    }
    return result;
}
export function calculateVolumeSMA(data: HistogramData[], count: number) {
    const avg = (data: HistogramData[]) => {
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
            sum += data[i].value;
        }
        return sum / data.length;
    };
    const result = [];
    for (let i = count - 1, len = data.length; i < len; i++) {
        const val = avg(data.slice(i - count + 1, i));
        result.push({ time: data[i].time, value: val });
    }
    return result;
}
