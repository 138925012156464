import { BoxProps, useColorMode, HStack, VStack, Text, Box, Divider, Stack } from '@chakra-ui/react';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../../style';
import { formatNumber } from '../../../../utils/tokenMath';
import { SwapForm } from '../../../../state/models/trade/swap/tradeSwap';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

type Props = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    handleSwap: () => void;
    swapForm: SwapForm;
} & BoxProps;

export const SwapConfirmModal: React.FC<Props> = (props) => {
    const { isOpen, onClose, onSuccess, handleSwap, swapForm, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '360px', sm: '818px' }} title="Confirming Swap" {...rest}>
            {!isMobile && <Divider mt="-20px !important" />}

            <Box
                bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')}
                w="100%"
                h={{ base: 'unset', sm: '100px' }}
                px="20px"
                pt={{ base: '30px', sm: '20px' }}
                pb={{ base: '30px', sm: 'unset' }}
            >
                <VStack>
                    <Text className={i_text_copy} fontWeight="bold" fontSize="14px" color={colorTheme('#FA4D6C', '#C54158')}>
                        Please confirm the Price Impact.
                    </Text>

                    <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} lineHeight="16px">
                        The price impact is over 10% ({formatNumber(Math.abs((swapForm.priceImpact ?? 0.1) * 100), 2, 2)}%) for this swap,
                        which means you are swapping under an inferior liquidity condition. Make sure the amount you will pay or receive is
                        acceptable.
                    </Text>
                </VStack>
            </Box>

            <Box w="100%" position="relative">
                <HStack w="600px" m="auto" spacing={{ base: '16px', sm: '50px' }}>
                    <CustomButton
                        variant="orange"
                        mt="20px !important"
                        text={
                            <HStack w="100%" position="relative">
                                <HStack mx="auto !important">
                                    <Text>Cancel</Text>
                                </HStack>
                            </HStack>
                        }
                        w={{ base: '150px', sm: '304px' }}
                        h={{ base: '46px', sm: '40px' }}
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            onClose();
                        }}
                    />
                    <CustomButton
                        variant="purple"
                        mt="20px !important"
                        text={
                            <HStack w="100%" position="relative">
                                <HStack mx="auto !important">
                                    <Text>Confirm Swap</Text>
                                </HStack>
                            </HStack>
                        }
                        w={{ base: '150px', sm: '304px' }}
                        h={{ base: '46px', sm: '40px' }}
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            handleSwap();
                            onClose();
                        }}
                    />
                </HStack>
            </Box>
        </Modal>
    );
};
