import { Stack, HStack, Text, useColorMode } from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';

import { RootState } from '../../../../../state/store';
import { noto_t3 } from '../../../../../style';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { trades } from '../OrderBook';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';

type RecentTradesBlockProps = {
    trades: trades[];
    themeColor: any;
};

const RecentTradesBlock: React.FC<RecentTradesBlockProps> = (props) => {
    const { trades, themeColor } = props;
    const { chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { pro, proControlState } = useSelector((state: RootState) => state);

    const gasToken = useGasToken();
    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);
    const { height } = useWindowDimensions();
    const orderHeight = height > 920 ? 488 : height > 865 ? height - 431 : 445;

    return (
        <Stack w="100%">
            <Stack w="100%">
                <HStack
                    w="100%"
                    className={noto_t3}
                    position="sticky"
                    top="0px"
                    bg={themeColor}
                    color={colorTheme('#767676', '#83789B')}
                    pb="5px"
                >
                    <Text w="33%" whiteSpace="nowrap">
                        Price({pro.tokenY.symbol})
                    </Text>
                    <Text w="33%" textAlign="center" whiteSpace="nowrap">
                        Quantity({proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol})
                    </Text>
                    <Text w="33%" textAlign="end">
                        Timestamp
                    </Text>
                </HStack>
                <Stack w="100%" spacing="2px" mt="0px !important" h={orderHeight} overflow="auto">
                    {trades.map((item, index) => {
                        const realPrice =
                            item.price &&
                            (item.actionType === 'buy'
                                ? item.price / (1 + toFeeNumber(pro.currentFee) / 100)
                                : item.price / (1 - toFeeNumber(pro.currentFee) / 100));

                        return (
                            <HStack
                                w="100%"
                                minH="20px"
                                h="20px"
                                className={noto_t3}
                                key={index}
                                bg={index % 2 === 1 ? colorTheme('#EEF0F3', '#160F29') : 'unset'}
                                borderRadius="2px"
                                px="8px"
                                _hover={{ bg: colorTheme('#DEE1FC', '#3A2F53') }}
                            >
                                <Text w="33%" color={item.actionType === 'buy' ? colorTheme('#12931F', '#47C87A') : '#CB1414'}>
                                    {realPrice && formatNumber(realPrice, 2, 2, true)}
                                </Text>
                                <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C6B8CF')}>
                                    {item.quantity && formatNumber(item.quantity, 2, 2, true)}
                                </Text>
                                <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C6B8CF')}>
                                    {moment(item.timestamp * 1000).format('HH:mm:ss')}
                                </Text>
                            </HStack>
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default RecentTradesBlock;
