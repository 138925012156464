import BigNumber from 'bignumber.js';
import { amount2Decimal } from '../../../../utils/tokenMath';
import { tick2PriceSqrt } from '../../../../utils/tickMath';
import { LiquidityDetail } from '../../../../state/models/trade/liquidity/types';

export const getAmountY = (liquidity: number, sqrtPriceL: number, sqrtPriceR: number, sqrtRate: number, upper: boolean): number => {
    const numerator = sqrtPriceR - sqrtPriceL;
    const denominator = sqrtRate - 1;
    if (!upper) {
        const amount = Math.floor((liquidity * numerator) / denominator);
        return amount;
    } else {
        const amount = Math.ceil((liquidity * numerator) / denominator);
        return amount;
    }
};

export const getAmountX = (
    liquidity: number,
    leftPt: number,
    rightPt: number,
    sqrtPriceR: number,
    sqrtRate: number,
    upper: boolean
): number => {
    const sqrtPricePrPc = Math.pow(sqrtRate, rightPt - leftPt + 1);
    const sqrtPricePrPd = Math.pow(sqrtRate, rightPt + 1);

    const numerator = sqrtPricePrPc - sqrtRate;
    const denominator = sqrtPricePrPd - sqrtPriceR;

    if (!upper) {
        const amount = Math.floor((liquidity * numerator) / denominator);
        return amount;
    } else {
        const amount = Math.ceil((liquidity * numerator) / denominator);
        return amount;
    }
};

export const getAmountYNoRound = (liquidity: number, sqrtPriceL: number, sqrtPriceR: number, sqrtRate: number): number => {
    const numerator = sqrtPriceR - sqrtPriceL;
    const denominator = sqrtRate - 1;
    const amount = (liquidity * numerator) / denominator;
    return amount;
};

export const getAmountXNoRound = (liquidity: number, leftPt: number, rightPt: number, sqrtPriceR: number, sqrtRate: number): number => {
    const sqrtPricePrPc = Math.pow(sqrtRate, rightPt - leftPt + 1);
    const sqrtPricePrPd = Math.pow(sqrtRate, rightPt + 1);

    const numerator = sqrtPricePrPc - sqrtRate;
    const denominator = sqrtPricePrPd - sqrtPriceR;

    const amount = (liquidity * numerator) / denominator;
    return amount;
};

export const getLiquidityValue = (liquidity: LiquidityDetail, tokenPriceAB: Pair<number, number>): number => {
    let amountX = 0;
    let amountY = 0;
    const liquid = Number(liquidity.liquidity);
    const sqrtRate = Math.sqrt(1.0001);
    // compute amountY
    if (Number(liquidity.leftPt) < liquidity.currentPt) {
        const rightPt: number = Math.min(liquidity.currentPt, Number(liquidity.rightPt));
        const sqrtPriceR = Math.sqrt(Math.pow(1.0001, rightPt));
        const sqrtPriceL = Math.sqrt(Math.pow(1.0001, Number(liquidity.leftPt)));
        amountY = getAmountY(liquid, sqrtPriceL, sqrtPriceR, sqrtRate, false);
    }

    // compute amountX
    if (Number(liquidity.rightPt) > liquidity.currentPt) {
        const leftPt: number = Math.max(liquidity.currentPt, Number(liquidity.leftPt));
        const rightPt = Number(liquidity.rightPt);
        const sqrtPriceR = Math.sqrt(Math.pow(1.0001, rightPt));
        amountX = getAmountX(liquid, leftPt, rightPt, sqrtPriceR, sqrtRate, false);
    }
    const amountXDecimal: number = amount2Decimal(new BigNumber(amountX), liquidity.tokenX) ?? 0;
    const amountYDecimal: number = amount2Decimal(new BigNumber(amountY), liquidity.tokenY) ?? 0;
    console.log('liquidity: ', liquidity);
    console.log(amountXDecimal, amountYDecimal);
    return amountXDecimal * tokenPriceAB.left + amountYDecimal * tokenPriceAB.right;
};

export const _calculateIzumiAmountDesired = (
    isLock0: boolean,
    amount0Desired: number,
    amount1Desired: number,
    tickLower: number,
    tickUpper: number,
    currentPt: number
): number[] => {
    let amount0DesiredNew = 0;
    let amount1DesiredNew = 0;
    let liquidity = 0;

    // boundary of token1
    const token1LeftPt = tickLower;
    const token1RightPt = Math.min(tickUpper, currentPt + 1);
    const token1SqrtPriceL = tick2PriceSqrt(token1LeftPt);
    const token1SqrtPriceR = tick2PriceSqrt(token1RightPt);

    // boundary of token0
    const token0LeftPt = Math.max(currentPt + 1, tickLower);
    const token0RightPt = tickUpper;
    const token0SqrtPriceR = tick2PriceSqrt(token0RightPt);
    const sqrtRate = Math.sqrt(1.0001);

    console.log(' -- token0 range: ', token0LeftPt, ' ', token0RightPt);
    console.log(' -- token1 range: ', token1LeftPt, ' ', token1RightPt);
    console.log(' -- tick range: ', tickLower, ' ', tickUpper);

    if (isLock0) {
        amount0DesiredNew = amount0Desired;
        console.log('lock first');
        if (token0LeftPt >= token0RightPt) {
            console.log('Only tokenB is required. This should not happen.');
            amount0DesiredNew = 0;
        } else if (token1LeftPt >= token1RightPt) {
            console.log('Only tokenA is required. No need to calc tokenB.');
        } else {
            liquidity = amount0Desired / getAmountXNoRound(1, token0LeftPt, token0RightPt, token0SqrtPriceR, sqrtRate);
            console.log(' -- liquidity: ', liquidity);
            amount1DesiredNew = getAmountY(liquidity, token1SqrtPriceL, token1SqrtPriceR, sqrtRate, true);
        }
    } else {
        amount1DesiredNew = amount1Desired;
        console.log('lock second');
        if (token1LeftPt >= token1RightPt) {
            console.log('Only tokenA is required. This should not happen.');
            amount1DesiredNew = 0;
        } else if (token0LeftPt >= token0RightPt) {
            console.log('Only tokenB is required. No need to calc tokenA.');
        } else {
            liquidity = amount1Desired / getAmountYNoRound(1, token1SqrtPriceL, token1SqrtPriceR, sqrtRate);
            console.log(' -- liquidity: ', liquidity);
            amount0DesiredNew = getAmountX(liquidity, token0LeftPt, token0RightPt, token0SqrtPriceR, sqrtRate, true);
        }
    }
    console.log(amount0Desired, amount1Desired);
    console.log(amount0DesiredNew, amount1DesiredNew);
    return [amount0DesiredNew, amount1DesiredNew];
};

// export const calculateIzumiAmountDesired = (
//     calForm: CalAmountDesiredAble,
//     currentPt: number,
//     tokenX: TokenInfoFormatted,
//     tokenY: TokenInfoFormatted
// ): number[] => {
//     const addressX = tokenX.address ?? '0x0';
//     const addressY = tokenY.address ?? '0x0';

//     const reverse: boolean = addressX.toUpperCase() > addressY.toUpperCase();

//     if (reverse) {
//         const [amount0, amount1] = _calculateIzumiAmountDesired(
//             !calForm.isLockFirstTokenVolume,
//             calForm.amount1Desired,
//             calForm.amount0Desired,
//             calForm.tickLower,
//             calForm.tickUpper,
//             currentPt
//         );
//         return [amount1, amount0];
//     } else {
//         return _calculateIzumiAmountDesired(
//             calForm.isLockFirstTokenVolume,
//             calForm.amount0Desired,
//             calForm.amount1Desired,
//             calForm.tickLower,
//             calForm.tickUpper,
//             currentPt
//         );
//     }
// };

export const _computeWithdrawXYAtCurrPt = (liquidDelta: number, sqrtPrice: number, liquidityY: number): number[] => {
    if (liquidityY >= liquidDelta) {
        return [0, liquidDelta * sqrtPrice];
    }
    const y = liquidityY * sqrtPrice;
    const x = (liquidDelta - liquidityY) / sqrtPrice;
    return [x, y];
};

export const _computeWithdrawXY = (liquidityY: number, currPt: number, liquidDelta: number, leftPt: number, rightPt: number): number[] => {
    let x = 0;
    let y = 0;
    const sqrtRate = Math.sqrt(1.0001);
    if (leftPt < currPt) {
        const tokenYRightPt: number = Math.min(currPt, rightPt);
        const tokenYLeftSqrtPrice: number = tick2PriceSqrt(leftPt);
        const tokenYRightSqrtPrice: number = tick2PriceSqrt(tokenYRightPt);
        y = getAmountY(liquidDelta, tokenYLeftSqrtPrice, tokenYRightSqrtPrice, sqrtRate, false);
    }
    if (rightPt > currPt + 1) {
        const tokenXLeftPt: number = Math.max(leftPt, currPt + 1);
        x = getAmountX(liquidDelta, tokenXLeftPt, rightPt, tick2PriceSqrt(rightPt), sqrtRate, false);
    }
    if (leftPt <= currPt && rightPt > currPt) {
        const [xc, yc] = _computeWithdrawXYAtCurrPt(liquidDelta, tick2PriceSqrt(currPt), liquidityY);
        x += xc;
        y += yc;
    }
    return [x, y];
};

export const getWithdrawToken = (liquidityY: number, currPt: number, liquidDelta: number, leftPt: number, rightPt: number): number[] => {
    return _computeWithdrawXY(liquidityY, currPt, liquidDelta, leftPt, rightPt);
};

export const mintNeedTokenX = (tickLower: number, tickUpper: number, currentTick: number): boolean => {
    if (tickLower >= tickUpper) {
        return false;
    }
    return tickUpper > currentTick + 1;
};

export const mintNeedTokenY = (tickLower: number, tickUpper: number, currentTick: number): boolean => {
    if (tickLower >= tickUpper) {
        return false;
    }
    return tickLower <= currentTick;
};

export const mintNeedTokenA = (
    tokenAAddress: string,
    tokenBAddress: string,
    tickLower: number,
    tickUpper: number,
    currentTick: number
): boolean => {
    if (!tokenAAddress || !tokenBAddress) {
        return false;
    }
    if (tickLower >= tickUpper) {
        return false;
    }
    if (tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()) {
        // tokenA is tokenX
        return mintNeedTokenX(tickLower, tickUpper, currentTick);
    } else {
        return mintNeedTokenY(tickLower, tickUpper, currentTick);
    }
};
