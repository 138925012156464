import { Stack, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { noto_t3 } from '../../../../../style';

type ChartInfoProps = {
    open: string;
    high: string;
    low: string;
    close: string;
    MA10: string;
    MA30: string;
    MA60: string;
    chartFullScreen?: boolean;
};
const ChartInfo: React.FC<ChartInfoProps> = (props) => {
    const { open, high, low, close, MA10, MA30, MA60, chartFullScreen } = props;
    const fullScreenStyle = {
        top: '100px',
        left: '50px',
        position: 'absolute',
        zIndex: '11',
    } as any;
    return (
        <Stack style={chartFullScreen ? fullScreenStyle : undefined}>
            <HStack className={noto_t3}>
                <Text w="110px">
                    O
                    <Text as="span" color="#47C87A" ml="5px">
                        {open}
                    </Text>
                </Text>
                <Text w="110px">
                    H
                    <Text as="span" color="#47C87A" ml="5px">
                        {high}
                    </Text>
                </Text>
                <Text w="110px">
                    L
                    <Text as="span" color="#47C87A" ml="5px">
                        {low}
                    </Text>
                </Text>
                <Text w="110px">
                    C
                    <Text as="span" color="#47C87A" ml="5px">
                        {close}
                    </Text>
                </Text>
            </HStack>
            <HStack className={noto_t3}>
                <Text w="80px" color="#767676">
                    MA(10,30,60)
                </Text>
                <Text w="110px" color="#D17A13">
                    MA10:
                    <Text as="span" ml="5px">
                        {MA10}
                    </Text>
                </Text>
                <Text w="110px" color="#3EAEFF">
                    MA30:
                    <Text as="span" ml="5px">
                        {MA30}
                    </Text>
                </Text>
                <Text w="110px" color="#EC35D8">
                    MA60:
                    <Text as="span" ml="5px">
                        {MA60}
                    </Text>
                </Text>
            </HStack>
        </Stack>
    );
};
export default React.memo(ChartInfo);
