import React, { useCallback } from 'react';
import { BoxProps, useColorMode } from '@chakra-ui/react';
import { i_text_copy_bold } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';

export type CancelButtonProps = {
    entry: any;
    onCancel?(): void;
} & BoxProps;

export const CancelButton: React.FC<CancelButtonProps> = (props) => {
    const { entry, onCancel, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return <CustomButton onClick={onCancel} variant="orange" text="Cancel" fontClass={i_text_copy_bold} {...rest}></CustomButton>;
};

export default CancelButton;
