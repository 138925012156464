import { useBreakpointValue, useColorMode } from '@chakra-ui/react';
import { CandlestickData, CrosshairMode, HistogramData, IChartApi, ISeriesApi } from 'lightweight-charts';
import { CandlestickSeries, Chart, HistogramSeries } from 'lightweight-charts-react-wrapper';
import React, { useMemo, useRef } from 'react';
import useIsMobile from '../../../../../hooks/useIsMobile';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import ChartSeries from './ChartSeries';
import HistogramMALineSeries from './HistogramChart/HistogramMALineSeries';
import MALineSeries from './MALineSeries';
export enum VisibleEnum {
    Candlestick = 'Candlestick',
    Histogram = 'Histogram',
}
type CandleChartProps = {
    chartWidth: number;
    height: number;
    handleCrosshairMove: any;
    chartRef: React.RefObject<IChartApi>;
    series: React.RefObject<ISeriesApi<'Candlestick'>>;
    MA10Ref: React.RefObject<ISeriesApi<'Line'>>;
    MA30Ref: React.RefObject<ISeriesApi<'Line'>>;
    MA60Ref: React.RefObject<ISeriesApi<'Line'>>;
    data: CandlestickData[];
    histogramData: HistogramData[];
    selectedInterval: string;
    chartFullScreen?: boolean;
    themeColor: any;
    histogramSeries: React.RefObject<ISeriesApi<'Histogram'>>;
    histogramMA10Ref: React.RefObject<ISeriesApi<'Line'>>;
    diffChartWidth: number;
    visible?: VisibleEnum;
};
const CandleChart: React.FC<CandleChartProps> = (props) => {
    const {
        chartWidth,
        height,
        handleCrosshairMove,
        chartRef,
        series,
        MA10Ref,
        MA30Ref,
        MA60Ref,
        data,
        histogramData,
        selectedInterval,
        chartFullScreen,
        themeColor,
        histogramSeries,
        histogramMA10Ref,
        diffChartWidth,
        visible,
    } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    // const { width } = useWindowDimensions();
    const isMobile = useIsMobile();
    const is2XL = useBreakpointValue({ base: false, xxl: false, '2xl': true })!;
    const isXl = useBreakpointValue({ base: false, xl: true, xxl: false, '2xl': false });
    const isXXl = useBreakpointValue({ base: false, xl: true, xxl: true, '2xl': false });
    const isLg = useBreakpointValue({ base: false, lg: true, xl: false });
    const { width } = useWindowDimensions();

    const histogramChartWidth = chartFullScreen
        ? width - diffChartWidth
        : isMobile
        ? width - 44 - diffChartWidth
        : is2XL
        ? width - 910 - diffChartWidth
        : isXXl
        ? width - 632 - diffChartWidth
        : isXl
        ? width - 663 - diffChartWidth
        : isLg
        ? width - 440 - diffChartWidth
        : 642 - diffChartWidth;

    return (
        <Chart
            ref={chartRef}
            width={visible === VisibleEnum.Candlestick ? chartWidth : histogramChartWidth}
            height={height}
            layout={{
                background: {
                    color: themeColor,
                },
                textColor: colorTheme('#B3B3B3', '#6A598C'),
                fontFamily: 'NotoSansArmenian-Regular',
            }}
            crosshair={{ mode: CrosshairMode.Normal }}
            onCrosshairMove={handleCrosshairMove}
            grid={{
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            }}
            timeScale={{ timeVisible: true, borderColor: colorTheme('#D9D9E7', '#6A598C') }}
            rightPriceScale={{ borderColor: colorTheme('#D9D9E7', '#6A598C') }}

            // watermark={{ visible: true, text: 'izumi', color: '#F2F2F3' }}
        >
            <CandlestickSeries
                data={data}
                reactive={true}
                ref={series}
                priceFormat={{
                    type: 'custom',
                    formatter: (price: number) => {
                        return formatNumber(price, 2, 2, true);
                    },
                    minMove: 0.00000001,
                }}
                upColor="#12931F"
                downColor="#CB1414"
                visible={visible === VisibleEnum.Candlestick ? true : false}
            />

            <MALineSeries
                MA10Ref={MA10Ref}
                MA30Ref={MA30Ref}
                MA60Ref={MA60Ref}
                data={data}
                visible={visible === VisibleEnum.Candlestick ? true : false}
            ></MALineSeries>
            <HistogramSeries
                ref={histogramSeries}
                data={histogramData}
                color="#26a69a"
                reactive={true}
                priceFormat={{
                    type: 'volume',
                    precision: 0,
                }}
                visible={visible === VisibleEnum.Histogram ? true : false}
            />
            <HistogramMALineSeries
                MA10Ref={histogramMA10Ref}
                data={histogramData}
                visible={visible === VisibleEnum.Histogram ? true : false}
            ></HistogramMALineSeries>
        </Chart>
    );
};

export default React.memo(CandleChart);
