import { Stack, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { noto_t3 } from '../../../../../../style';

type HistogramInfoProps = {
    volume: string;
    MA10: string;
    chartFullScreen?: boolean;
};
const HistogramInfo: React.FC<HistogramInfoProps> = (props) => {
    const { volume, MA10, chartFullScreen } = props;
    const fullScreenStyle = {
        bottom: '180px',
        left: '50px',
        position: 'absolute',
        zIndex: '11',
    } as any;
    return (
        <Stack style={chartFullScreen ? fullScreenStyle : undefined}>
            <HStack className={noto_t3}>
                <Text w="50px" color="#767676">
                    VOL(10)
                </Text>
                <Text w="120px" color="#E68617">
                    MA10:
                    <Text as="span" color="#E68617" ml="5px">
                        {MA10}
                    </Text>
                </Text>
                <Text w="130px" color="#47C87A">
                    VOLUME:
                    <Text as="span" color="#47C87A" ml="5px">
                        {volume}
                    </Text>
                </Text>
            </HStack>
        </Stack>
    );
};
export default React.memo(HistogramInfo);
