import { useColorMode } from '@chakra-ui/react';
import { CandlestickData, CrosshairMode, IChartApi, ISeriesApi } from 'lightweight-charts';
import { CandlestickSeries, Chart } from 'lightweight-charts-react-wrapper';
import React from 'react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatNumber } from '../../../../utils/tokenMath';

type CandleChartProps = {
    chartWidth: number;
    chartRef: React.RefObject<IChartApi>;
    series: React.RefObject<ISeriesApi<'Candlestick'>>;
    data: CandlestickData[];
    themeColor: any;
    handleCrosshairMove: any;
};
const CandleChart: React.FC<CandleChartProps> = (props) => {
    const { chartWidth, chartRef, series, data, themeColor, handleCrosshairMove } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Chart
            ref={chartRef}
            width={chartWidth}
            height={314}
            layout={{
                background: {
                    color: themeColor,
                },
                textColor: colorTheme('#B3B3B3', '#6A598C'),
                fontFamily: 'NotoSansArmenian-Regular',
            }}
            crosshair={{ mode: CrosshairMode.Normal }}
            onCrosshairMove={handleCrosshairMove}
            grid={{
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            }}
            timeScale={{
                timeVisible: true,
                borderColor: colorTheme('#D9D9E7', '#6A598C'),
            }}
            rightPriceScale={{ borderColor: colorTheme('#D9D9E7', '#6A598C') }}

            // watermark={{ visible: true, text: 'izumi', color: '#F2F2F3' }}
        >
            <CandlestickSeries
                data={data}
                reactive={true}
                ref={series}
                priceFormat={{
                    type: 'custom',
                    formatter: (price: number) => {
                        return formatNumber(price, 2, 2, true);
                    },
                    minMove: 0.00000001,
                }}
                upColor="#12931F"
                downColor="#CB1414"
            />
        </Chart>
    );
};

export default React.memo(CandleChart);
